import { Button } from "antd";
import color from "../../resource/color";
import Text from "../../components/Text/Text";
import React from "react";
import { ButtonProps } from "antd/lib";

interface Props {
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  onClick?: () => void;
  text: string;
  bgColor?: string;
  htmlType?: ButtonProps["htmlType"];
}

function Buttons({ style, icon, onClick, text, bgColor, htmlType }: Props) {
  return (
    <Button
      style={{
        backgroundColor: bgColor || color.navy,
        borderColor: bgColor || color.navy,
        ...style,
      }}
      onClick={onClick}
      htmlType={htmlType}
    >
      {icon}
      <Text color="white" fontWeight={700} fontSize={16}>
        {text}
      </Text>
    </Button>
  );
}

export default Buttons;
